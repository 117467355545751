import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate() {
    if(!localStorage.getItem('onboardingCompleted') || localStorage.getItem('onboardingCompleted') !== 'true'){
      this.router.navigateByUrl('/onboarding').then();
      return false;
    } else {
      return true;
    }
  }
}
