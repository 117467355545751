import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private language$ = new BehaviorSubject(environment.defaultLocale);

  constructor() {}

  getLanguage(): Observable<string> {
    return this.language$.asObservable();
  }

  setLanguage(lang: string): void {
    this.language$.next(lang);
  }
}
