import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import {LanguageService} from './core/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('phone', {static: false}) phone: ElementRef;

  currentTime: string;
  intervalId: any;
  browserLang;
  constantPhoneHeight = 844;
  innerHeight = window.innerHeight;
  isMobile: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private cdr: ChangeDetectorRef
  ) {
    this.initializeApp();
    this.isMobile = window.innerWidth <= 576;
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    let ratio = this.innerHeight / this.constantPhoneHeight - 0.1;
    if (!this.isMobile) {
      if (ratio < 1) {
        this.phone.nativeElement.style.transform = `scale(${ratio})`;
      } else {
        ratio = ratio - 0.1;
      }
      this.phone.nativeElement.style.transform = `scale(${ratio})`;
    }

    this.getTime();
    this.intervalId = setInterval(() => this.getTime(), 60000);

    this.browserLang = navigator.language;
    this.languageService.getLanguage().subscribe((lang) => {
      if(this.browserLang.toLowerCase().includes('en')){
        this.translateService.use('en');
        this.languageService.setLanguage('en');
      }else{
        this.translateService.use(lang);
      }
    });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.splashScreen.hide();
    });
  }

  getTime() {
    const value = new Date();
    const hours = value.getHours();
    const minutes = value.getMinutes();

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    this.currentTime = `${formattedHours}:${formattedMinutes}`;
  }

}
