export const environment = {
  production: false,
  locales: ['en'],
  defaultLocale: 'en',
  glassfyKey: 'bcafa7e0133f4bad92e3ceaccd895245',
  firebase: {
    apiKey: 'AIzaSyDuHo7-Du6M9OCEG1V5fxmqZF_IwxB4erA',
    authDomain: 'sparklly.firebaseapp.com',
    projectId: 'sparklly',
    storageBucket: 'sparklly.appspot.com',
    messagingSenderId: '4620072017',
    appId: '1:4620072017:web:92aeab8224c8f27c933470',
    measurementId: 'G-TYYPCLCDM0'
  }
};
