import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { CanActivate, Router } from '@angular/router';
import {FirebaseAuthentication} from '@capacitor-firebase/authentication';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: Auth, private router: Router, private platform: Platform) {}

  canActivate(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const isIOSPlatform = this.platform.is('capacitor');
      if (isIOSPlatform) {
        const result = await FirebaseAuthentication.getCurrentUser();
        if (result?.user && result?.user?.uid) {
          resolve(true);
        } else {
          this.router.navigateByUrl('/login', { replaceUrl: true }).then();
          reject(false);
        }
      } else {
        onAuthStateChanged(this.auth, async user => {
          if (user) {
            resolve(true);
          } else {
            this.router.navigateByUrl('/login', { replaceUrl: true }).then();
            reject(false);
          }
        });
      }
    });
  }

}
